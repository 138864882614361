body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	scroll-behavior: smooth;
}

body.dark {
	background: radial-gradient(
		111.11% 111.11% at 50% 41.11%,
		#403748 21.88%,
		#07010c 100%
	);
	color: #f8f1f1;
}

body.light {
	background: radial-gradient(
		60.83% 60.83% at 50% 39.17%,
		#ffffff 21.87%,
		#ededed 100%
	);
	color: #000;
}

::-webkit-scrollbar-track {
	background: linear-gradient(135deg, #e1edf8 0%, #eef1f3 100%);
	box-shadow: inset -12px -10px 12px rgba(255, 255, 255, 0.750601),
		inset 0px 9px 18px #c9d3e2, inset 0px 2px 22px #c8d4e2;
	border-radius: 28px;
}

::-webkit-scrollbar {
	width: 0.6em;
	background: linear-gradient(135deg, #e1edf8 0%, #eef1f3 100%);
	box-shadow: inset -12px -10px 12px rgba(255, 255, 255, 0.750601),
		inset 0px 9px 18px #c9d3e2, inset 0px 2px 22px #c8d4e2;
	border-radius: 28px;
}

::-webkit-scrollbar-thumb {
	border-radius: 23.5px;
	background: linear-gradient(295.74deg, #ccc, #bbb);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

#root {
	min-height: 100vh;
}

.reloadAlbums {
	display: inline-block;
	vertical-align: bottom;
	padding-left: 10px;
}
.reloadAlbums img {
	width: 32px;
}
.parent_folder_container button {
	display: inline-block;
	border: none;
	background: none;
	color: #f00;
	text-transform: capitalize;
}
.parent_folder_container > div {
	display: inline-block;
}
.parent_folder_container button:last-child span {
	display: none;
}
.parent_folder_container button span {
	padding-left: 10px;
}
.parent_folder_container > div button:last-child span {
	display: inline-block;
}
button.back_sub_folder {
	background: #000;
	display: inline-block;
	color: #fff;
	margin: 0 10px 10px 0;
	padding: 7px 15px;
}
.gap_20 {
	margin-top: 20px;
}
button.home_sub_folder span.MuiIconButton-label {
	padding: unset;
}
button.home_sub_folder {
	text-align: center;
	color: #000;
}
.button_rc {
	display: inline-block;
}
.delete_rc.MuiChip-deleteIcon {
	background: #00000042;
	border-radius: 50%;
	height: 16px;
	width: 16px;
}
/* .uploadPic .MuiGrid-grid-lg-6 .MuiPaper-rounded,
.uploadPic .MuiGrid-grid-lg-3 .MuiPaper-rounded{
  min-height: 635px;
} */
.updatePicMin .MuiPaper-rounded,
.updatePicLft .MuiPaper-rounded {
	min-height: 475px;
}
.uploadPic .MuiGrid-grid-lg-3 .div-img-wrapper {
	display: inline-flex;
	justify-content: center;
	align-items: flex-end;
	min-height: 443px;
}
.uploadPic .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-6 {
	justify-content: center;
	align-items: flex-end;
}
.uploadPic .MuiGrid-grid-lg-3 .div-img-wrapper img {
	width: 100% !important;
	height: auto !important;
}
.uploadPic .MuiGrid-grid-lg-6 img {
	width: 100% !important;
	height: auto !important;
}
.uploadPic
	header
	.MuiToolbar-gutters
	button.MuiButtonBase-root.MuiIconButton-colorInherit.MuiIconButton-edgeStart {
	padding: 5px !important;
}
.uploadPic button.MuiButton-contained.MuiButton-containedPrimary {
	margin-bottom: 4px;
	border-radius: 0px !important;
}
.partSeg h3 {
	margin: 0px;
	font-weight: 600;
	font-size: 15px;
	border-bottom: 2px solid #efefef;
	padding-bottom: 5px;
	text-align: center;
	margin-bottom: 5px;
}
.partSegUpr,
.faceSegUpr {
	display: flex;
	align-items: center;
}
.partSegUprLft,
.partSegUprRght,
.faceSegUprLft,
.faceSegUprRght {
	width: 50%;
}
.partSegUprRght,
.faceSegUprRght {
	text-align: right;
}
.partSegUprRght .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track,
.faceSegUprRght .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
	background-color: #015401;
}
.partSegUprRght .MuiSwitch-colorSecondary.Mui-checked,
.faceSegUprRght .MuiSwitch-colorSecondary.Mui-checked {
	color: #fff !important;
}
.faceRecog h4 {
	margin: 0px;
	font-weight: 600;
	font-size: 15px;
	margin-top: 12px;
	border-bottom: 2px solid #efefef;
	padding-bottom: 5px;
	text-align: center;
	margin-bottom: 5px;
}
.checkHghtWght label {
	margin-right: 0px !important;
	font-size: 14px !important;
	margin-bottom: 6px;
	margin-left: 0px !important;
}
.checkHghtWght label span {
	font-size: 15px !important;
	padding: 0px !important;
}
.faceRecog {
	margin-bottom: 4px;
}
.checkHghtWght .MuiCheckbox-colorSecondary.Mui-checked {
	color: #445eec !important;
}
.partSegUprRght .MuiSwitch-root,
.faceSegUprRght .MuiSwitch-root {
	padding: 6px !important;
}
.partSegUprRght .MuiSwitch-track,
.faceSegUprRght .MuiSwitch-track {
	border-radius: 12px !important;
}
.reactEasyCrop_Container {
	z-index: 999 !important;
}
.container-img {
	position: relative;
}

.image-org {
	display: block;
	width: 100%;
	height: auto;
	top: 0;
	left: 0;
}

.image-mask {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	transition: 0.5s ease;
	opacity: 0;
}

.image-mask {
	opacity: 1;
}

.div-img-wrapper {
	cursor: pointer;
}

.div-loader {
	display: flex;
	justify-content: center;
	position: absolute;
	background: rgba(255, 255, 255, 0.8);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0px;
	z-index: 99;
	align-items: center;
	overflow: hidden;
	margin: 0 auto;
}
.fill {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}
.fill img {
	flex-shrink: 0;
	min-width: 100%;
	min-height: 100%;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

.Resizer {
	background: transparent;
	opacity: 0.2;
	z-index: 1;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-moz-background-clip: padding;
	-webkit-background-clip: padding;
	background-clip: padding-box;
}

.Resizer:hover {
	-webkit-transition: all 2s ease;
	transition: all 2s ease;
}

.Resizer.horizontal {
	height: 2px;
	width: 90%;
	margin: 0px auto 5px auto;
	border-top: 1px solid white;
	border-bottom: 0px solid transparent;
	cursor: row-resize;
	-webkit-box-shadow: 0px 4px 9px 1px rgb(0 0 0 / 60%);
	box-shadow: 0px 4px 9px 1px rgb(0 0 0 / 60%);
}

.Resizer.vertical {
	width: 11px;
	margin: 15% -5px;
	border-left: 5px solid rgba(255, 255, 255, 0);
	border-right: 5px solid rgba(255, 255, 255, 0);
	cursor: col-resize;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
	display: flex;
	justify-content: flex-start;
}

.slick-initialized .slick-slide {
	margin-top: auto;
}

.Toastify__toast--dark {
	padding: 12px;
	border-radius: 10px;
	min-width: 800px;
}
